import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import { defaultTheme } from './themes/defaultTheme';
import { beautifulMentionsTheme } from './themes/CommentEditorTheme';
import { handleRouteError } from '../../logger';
import { InitialConfigType } from '@lexical/react/LexicalComposer';
import { MarkNode } from '@lexical/mark';
import { BeautifulMentionNode } from 'lexical-beautiful-mentions';

export const defaultConfig = {
  namespace: 'RichTextEditor',
  theme: defaultTheme,
  onError: (error: Error) => {
    handleRouteError(error);
  },
  nodes: [ListNode, ListItemNode, HeadingNode],
};

export const collabEditorConfig: InitialConfigType = {
  ...defaultConfig,
  theme: {
    ...defaultTheme,
    beautifulMentions: beautifulMentionsTheme,
  },
  editorState: null,
  namespace: 'CollaborationEditor',
  nodes: [ListNode, ListItemNode, HeadingNode, MarkNode, BeautifulMentionNode],
};
