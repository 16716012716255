// Lexical theme includes customizable theming object that maps CSS class names to the editor and css file. https://lexical.dev/docs/getting-started/theming

import { defaultTheme } from './defaultTheme';
import './CommentEditorTheme.scss';

import { BeautifulMentionsTheme } from 'lexical-beautiful-mentions';
// ...
export const beautifulMentionsTheme: BeautifulMentionsTheme = {
  // 👇 use the trigger name as the key
  '@': 'px-1 text-ThemeAccentExtradark background-ThemeAccentExtralight',
  // 👇 add the "Focused" suffix to style the focused mention
  '@Focused': 'outline-none shadow-md',
}



export const CommentEditorTheme = {
  ...defaultTheme,
  paragraph: 'CommentEditorTheme__paragraph',
  beautifulMentions: beautifulMentionsTheme,
}
