import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { $getRoot, EditorState } from 'lexical';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { UNORDERED_LIST, ORDERED_LIST } from '@lexical/markdown';

import { OnChangePlugin } from './plugins/OnChangePlugin';
import './styles.scss';
import { ToolbarPlugin } from './plugins/ToolbarPlugin';
import { defaultConfig } from './constants';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { EditorPlaceholder } from './EditorPlaceholder';

const initialConfig = {
  ...defaultConfig,
  namespace: 'RichTextEditor',
};

export const RichTextEditorContainer = ({ children }: { children: JSX.Element }) => {
  return <LexicalComposer initialConfig={initialConfig}>{children}</LexicalComposer>;
};

interface Props {
  placeholder?: string;
  disabled?: boolean;
  handleChange: ({ editorState, plainText }: { editorState: EditorState; plainText: string }) => void;
  autoFocus?: boolean;
}

export const RichTextEditor = ({ disabled, placeholder, handleChange, autoFocus }: Props) => {
  const getPlainTextBeforeHandleChange = (editorState: EditorState) => {
    const plainText = editorState.read(() => $getRoot().getTextContent());
    handleChange({ editorState, plainText });
  };

  return (
    <div className='editor-container position-relative'>
      {disabled ? null : <ToolbarPlugin />}
      <div className='position-relative'>
        {autoFocus ? <AutoFocusPlugin /> : null}
        <RichTextPlugin
          contentEditable={<ContentEditable className='editor-input position-relative px-2 pt-1 pb-3' />}
          placeholder={<EditorPlaceholder placeholder={placeholder} />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
        <HistoryPlugin />
        <OnChangePlugin onChange={getPlainTextBeforeHandleChange} />
        <MarkdownShortcutPlugin transformers={[UNORDERED_LIST, ORDERED_LIST]} />
      </div>
    </div>
  );
};
